<template >
  <div>
    <el-dropdown trigger="click" @command="handleCommand">
      <i class="el-icon-more"/>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
         v-for="item in actionList"
         :key="item.key"
         :icon="item.icon"
         :command="item.key">{{item.label}}</el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
export default {
  name: 'DropdownActionMenu',
  props: {
    actionList: {
      type: Array,
      required: true
    },
    propsData: {
      type: Object,
      default: null,
      required: false
    }
  },
  data () {
    return {
      openPopconfirm: false
    }
  },
  methods: {
    handleCommand (action) {
      if (action === 'delete') {
        this.$confirm('Are you sure to delete?', {
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Delete'
        }).then(() => {
          this.$emit('handleDropdownMenuClick', 'delete', this.propsData)
          // this.$message({
          //   type: 'success',
          //   message: 'Delete completed'
          // })
        }).catch(() => {
        })
      } else {
        this.$emit('handleDropdownMenuClick', action, this.propsData)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .el-dropdown-item:hover {
    background-color: #E7EBF0;
    color: #3f5f88;
    cursor:pointer;
  }
</style>
